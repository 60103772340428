<template>
  <div v-bind:class="parentClass">
    <div v-if="!submitStatus">
      <button
        type="submit"
        class="btn"
        v-bind:class="{
          'btn-disabled disabled': disabled,
          'btn-disabled disabled btn-danger': !permission,
          'btn-success': permission,
        }"
        v-on:click.prevent="submitForm()"
        v-tooltip="tooltip"
      >
        <!-- Icon -->
        <i
          v-if="permission"
          class="mr-2"
          v-bind:class="{
            'fas fa-save': !update,
            'fas fa-sync-alt': update,
          }"
        ></i>

        <i v-else class="fas fa-exclamation-triangle text-white mr-2"></i>
        <!-- ./Icon -->

        {{ submitButtonText }}
      </button>
    </div>
    <button v-else class="btn btn-success">
      <i class="fas fa-spinner fa-spin"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: "WeSubmitButton",
  props: {
    parentClass: {
      default: "",
    },
    submitStatus: {
      default: false,
    },
    update: {
      default: null,
    },
    permission: {
      default: true,
    },
    disabled: {
      default: false,
    },
    btnText: {
      default: null,
    },
  },
  methods: {
    submitForm() {
      if (this.permission && !this.disabled) {
        this.$emit("submit");
      }
    },
  },
  computed: {
    submitButtonText() {
      let result = this.btnText;

      if (!result) {
        result = this.update ? this.$t("update") : this.$t("save");
      }

      return result;
    },
    tooltip() {
      if (!this.permission) {
        return {
          content: this.$t("youAreNotAuthorizedForThisOperation"),
          placement: "top",
        };
      }
    },
  },
};
</script>
